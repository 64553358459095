import React from "react";
import { Link, } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO
      seo={{
        metaTitle: "404: Nerasta",
      }}
    />
    <h1>Deja, bet negalime įvykdyti šios užklausos</h1>
    <p>Grįžkite į <Link to={'/'}>pradinį puslapį</Link>, o dėl šios klaidos ištaisymo kreipkitės į sistemos administratorių.</p>
  </Layout>
);

export default NotFoundPage;
